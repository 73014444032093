import axios, { AxiosError } from 'axios'
import 'bootstrap/dist/css/bootstrap-reboot.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'font-awesome/css/font-awesome.css'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { useStore } from 'react-redux'
import { useHistory } from 'react-router'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import swal from 'sweetalert2'
import LoadingIndicator from 'srs.sharedcomponents/lib/esm/components/LoadingIndicator'
import OpenSourcesComponent from 'srs.sharedcomponents/lib/esm/components/openSources.component'
import PrefferencesComponent from 'srs.sharedcomponents/lib/esm/components/prefferences/srs.prefferences.component'
import PrefferencesChangePasswordComponent from 'srs.sharedcomponents/lib/esm/components/prefferences/srs.prefferencesChangePassword.component'
import StaticPageRender from 'srs.sharedcomponents/lib/esm/components/srs.staticPageRender.component'
import ParameterSelector from 'srs.sharedcomponents/lib/esm/components/webPartEditor/parameterSelector'
import './css/main.css'
import { i18nNamespaces as namespace } from 'srs.sharedcomponents/lib/esm/i18n/i18n'
import { IEmbededPage } from 'srs.sharedcomponents/lib/esm/models/srs.embededPage.model'
import { IWelcome } from 'srs.sharedcomponents/lib/esm/models/srs.welcome.model'
import {
  useAppDispatch,
  useAppSelector,
} from 'srs.sharedcomponents/lib/esm/redux/hooks'
import {
  clearAll as clearReduxCart,
  setDefaultCartState, 
  cartEnabled,
  cartAllowed,
  setDisplayMessageFlag 
} from 'srs.sharedcomponents/lib/esm/redux/slices/cartSlice'
import { clearState as clearCheckout } from 'srs.sharedcomponents/lib/esm/redux/slices/checkoutSlice'
import { initState as clearQuickOrder } from 'srs.sharedcomponents/lib/esm/redux/slices/quickOrderSlice'
import {
  clearState as clearUser,
  setCountryAndLanguage,
} from 'srs.sharedcomponents/lib/esm/redux/slices/userSlice'
import {
  dbCountry,
  dbLanguage,
  getWelcome,
  isCurrentCountryIndependent,
  mainMenuItems,
  setLinksLocale,
  setWelcome,
  setIsShowAllMenuInCapitals,
} from 'srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice'
import {
  clearReduxWishList,
  setDefaultWishListState,
} from 'srs.sharedcomponents/lib/esm/redux/slices/wishlistSlice'
import { RootState } from 'srs.sharedcomponents/lib/esm/redux/store'
import AccordionComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.accordion.component'
import SrsCartComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.cart.component'
import CheckoutComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.checkout.component'
import ConsentModalComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.consentModal.component'
import ContactUsComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.contactus.component'
import CorporateInfoComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.corporateInfo.component'
import EnhancedRegistrationActivation from 'srs.sharedcomponents/lib/esm/shared-components/srs.enhancedRegistrationActivation.component'
import FooterComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.footer.component'
import { setFooterLinks, } from 'srs.sharedcomponents/lib/esm/redux/slices/footerLinksSlice'
import HeaderComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.header.component'
import ItemDetailComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.itemDetail.component'
import LoanToolProduct from 'srs.sharedcomponents/lib/esm/shared-components/srs.loanToolProduct.component'
import LoginComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.login.component'
import NewsletterProcessor from 'srs.sharedcomponents/lib/esm/shared-components/srs.newsletterProcessor.component'
import NotFoundPageComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.notFoundPage.component'
import OrderHistoryComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.orderHistory.component'
import OrderHistoryDetailsComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.orderHistoryDetails.component'
import PageTitle from 'srs.sharedcomponents/lib/esm/shared-components/srs.pageTitle.component'
import PasswordRecoveryComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.passwordRecovery.component'
import ProductListComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.productList.component'
import QuickOrderComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.quickOrder.component'
import RegistrationComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.registration.component'
import SsoComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.sso.component'
import ThankyouComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.thankyou.component'
import WebAccessibilityComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.webAccessibility.component'
import WebPartEditorComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.webPartEditor.component'
import WelcomeComponent from './srs.welcome.component'
import SrsWishlistComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.wishlist.component'
import DunningBlockModalComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.dunningBlockModal.component'
import CustomerErrorModalComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.customerErrorModal.component'
import { locales } from 'srs.sharedcomponents/lib/esm/utils/dictionaries'
import {
  LinkType,
  RoleEnum,
} from 'srs.sharedcomponents/lib/esm/utils/enums'
import {
  setErrorText,
  stringToBoolean,
} from 'srs.sharedcomponents/lib/esm/utils/helpers'
import VirtualToolBoxComponent from 'srs.sharedcomponents/lib/esm/components/pages/VirtualToolbox/VirtualToolbox'
import LoanCartcomponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.loanCart.component'
import LoanCheckoutComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.loanCheckout.component'
import RepairProceduresComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.repairProcedures.component'
import EsstComponent from './srs.esst.component'
import { globalStyles } from 'srs.sharedcomponents/lib/esm/styles/global'
import OthersWishListPage from 'srs.sharedcomponents/lib/esm/components/pages/OthersWishList/OthersWishListPage'
import FranchiseDialog from 'srs.sharedcomponents/lib/esm/components/molecules/FranchiseDialog/FranchiseDialog'
import MinimumStandardComponent from './srs.minimumStandard.component'
import coreSrsPrimaryOverride from './i18n/coreSrsPrimaryOverride.json'
import MetaSetter from 'srs.sharedcomponents/lib/esm/components/molecules/MetaSetter/MetaSetter';
import PrivacyLogout from 'srs.sharedcomponents/lib/esm/components/pages/PrivacyLogout/PrivacyLogout'
import { handleClientError, handleNoResponse, handleServerError } from 'srs.sharedcomponents/lib/esm/utils/axiosInterceptors'

globalStyles()

interface ISharedAppProps {
 readonly  itemListPlaceholder?: () => React.ReactNode
}

function SharedApp({ itemListPlaceholder }: ISharedAppProps) {
  const { i18n, t } = useTranslation()
  Object.entries(coreSrsPrimaryOverride).forEach(([locale, entity]) => {
    Object.entries(entity).forEach(([key, value]) => {
      i18n.addResource(locale, namespace.coreSrsPrimary, key, value)
    })
  })
  const cookeisPages: IEmbededPage[] = [
    {
      name: t(`Cookie_Policy_header`, { ns: namespace.srsCookies }),
      src: t(`Cookie_Policy`, { ns: namespace.srsCookies }),
    },
    {
      name: t(`Third_party_cookies_header`, { ns: namespace.srsCookies }),
      src: t(`Third_party_cookies`, { ns: namespace.srsCookies }),
    },
    {
      name: t(`Cookies_used_by_this_websites_header`, {
        ns: namespace.srsCookies,
      }),
      src: t(`Cookies_used_by_this_websites`, { ns: namespace.srsCookies }),
    },
  ]
  const welcome = useAppSelector((state: RootState) => state?.welcome?.welcome)
  const store = useStore()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const [isLeftSideMenuExpand, setIsLeftSideMenuExpand] = useState(false)
  const menuItems = useAppSelector(mainMenuItems)
  const isLoanEnabled = useAppSelector((state) => state?.user.user?.rentEnabled)
  const userLang = useAppSelector((state) => state.user.user?.languageId)
  const user = useAppSelector((state) => state.user.user)
  const language = useAppSelector(dbLanguage)
  const country = useAppSelector(dbCountry)
  const isCurrCountryIndependent = useAppSelector(isCurrentCountryIndependent)

  const { links } = useAppSelector((state) => state.footerLinks)
  const includeWebAccessibilityLink = false;
  const isCartEnabled = useAppSelector(cartEnabled)
  const isCartAllowed = useAppSelector(cartAllowed)

  useEffect(() => {
    const setCustomFooterLinks = () => {
      const footerLinks = includeWebAccessibilityLink ? 
      links : links.filter(link => link.id !== 'footerWebAccessibility');

      if(links.length !== footerLinks.length)
      {
        dispatch(setFooterLinks(footerLinks));
      }
    }

    setCustomFooterLinks()
  }, [links])

  useEffect(() => {
    setGA()
    saveLanguageToLocalStorage()
    if (welcome?.anonymousId === '') {
      dispatch(getWelcome())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcome?.anonymousId])

  useEffect(() => {
    if (!userLang) {
      dispatch(
        setCountryAndLanguage({
          country: Number(country),
          language: Number(language),
        }),
      )
      dispatch(setLinksLocale(Number(language)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  const setUnauthorizedState = async () => {
    if (store.getState().user?.user?.token) {
      history.push('/')

      dispatch(clearUser())
      dispatch(clearReduxWishList())
      dispatch(clearReduxCart())
      dispatch(clearCheckout())
      dispatch(clearQuickOrder())

      history.push('/login')
    } else {
      dispatch(setWelcome({ anonymousId: '' } as IWelcome))
      dispatch(getWelcome())
      dispatch(setDefaultCartState())
      dispatch(setDefaultWishListState())
    }
  }

  const renderLoginRoutes = () => {
    let loginRoutes = [
      {
        component: <LoginComponent />,
        path: '/login',
        pageTitle: t(`txtLogin`, { ns: namespace.coreSrsPrimary }),
      },
      {
        component: <PasswordRecoveryComponent />,
        path: '/PasswordRecovery',
        pageTitle: t(`RecoverPasswordTitle`, { ns: namespace.coreSrsPrimary }),
      },
      {
        component: <PasswordRecoveryComponent />,
        path: '/:getLangRoute/pagesSecurity/PasswordRecovery',
        pageTitle: t(`RecoverPasswordTitle`, { ns: namespace.coreSrsPrimary }),
      },
      {
        component: <PasswordRecoveryComponent />,
        path: '/:getLangRoute/Pages/PasswordRecovery.aspx',
        pageTitle: t(`RecoverPasswordTitle`, { ns: namespace.coreSrsPrimary }),
      },
      {
        component: <PasswordRecoveryComponent />,
        path: '/:getLangRoute/Security/PasswordRecovery',
        pageTitle: t(`RecoverPasswordTitle`, { ns: namespace.coreSrsPrimary }),
      },
      {
        component: <RegistrationComponent />,
        pageTitle: t(`RegisterLinkText`, { ns: namespace.coreSrsPrimary }),
        path: '/registration',
      },
      {
        component: <PrefferencesComponent />,
        pageTitle: t(`txtPreference`, { ns: namespace.coreSrsPrimary }),
        path: '/mypreferences',
      },
    ]

    const shouldLoginPagesBeRendered =
      !!isCurrCountryIndependent ||
      stringToBoolean(welcome?.siteSettings['Srs.IgnoreCountryDependence'])

    if (shouldLoginPagesBeRendered) {
      return loginRoutes
    } else {
      return []
    }
  }

  axios.interceptors.response.use(
    (response) => response, // Directly return responses that are received without error
    async (error: AxiosError) => {
      if(!error.response){
        handleNoResponse(error, t, namespace.coreSrsPrimary, swal)
        return
      }
  
      const { status } = error.response || {};
  
      const isNotError = status >= 200 && status < 300;
      const isClientError = status >= 400 && status < 500;
      const isServerError = status >= 500;
  
      if(isNotError) {
        return Promise.resolve("No error found.")
      }
  
      if (error.response) {
        // Handle known error statuses
        if (isClientError) {
          return handleClientError(error, status, setUnauthorizedState, namespace.coreSrsPrimary, t);
        } else if (isServerError) {
          return handleServerError(error, setErrorText(error, status));
        }
      }
    }
  );


  /**
   * Validate if left menu will be enabled for product list component
   */
  const isLeftMenuEnabledForProductList = () => {
    const tagValue = new URLSearchParams(location.search).get('TAG')
    const letMenuEnabled = tagValue?.toUpperCase() !== 'CONSUMABLES'
    return letMenuEnabled
  }

  /**
   * Open and close left side mobile menu
   */
  const toggleLeftSideMobileMenu = () => {
    setIsLeftSideMenuExpand((isLeftMenuExpand) => !isLeftMenuExpand)
  }

  /**
   * Reset Scroll position on change of Routes
   */
  history.listen(() => {
    window.scrollTo(0, 0)
  })

  const setGA = () => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`, {
      debug: false,
    })
  }

  const saveLanguageToLocalStorage = () => {
    const lang = locales.get(Number(store.getState().user.user.languageId))
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }
  const publicRoutes = [
    '/login',
    '/PasswordRecovery',
    '/registration',
    '/contactus',
    '/cookies',
    '/warranty',
    '/opensources',
    '/getcorporateinformation',
    '/webaccessibility',
    '/Security/PasswordRecovery',
    '/Pages/PasswordRecovery.aspx',
    '/pagesSecurity/PasswordRecovery',
    '/ssoprocessing',
    '/sso',
    '/userActivation',
    '/newsletterprocessor',
  ]
  const getLangRoute = locales.get(
    Number(store.getState().user.user.languageId),
  )
  useEffect(() => {
    //This will capitalize the left manu and top menu FOR JLR only
    
    dispatch(setIsShowAllMenuInCapitals(true))
  }, [welcome])
  useEffect(() => {
    //this will show message on product search for JLR only 
    dispatch(setDisplayMessageFlag(true))
  }, [])
  if (welcome?.anonymousId === '') return <LoadingIndicator />
  if (
    !store.getState().user?.user?.countryId &&
    !publicRoutes.find((publicRoute) =>
      location?.pathname.includes(publicRoute),
    )
  ) {
    return <Redirect to="/mypreferences" />
  }
  return (
    <div className="App">
      <MetaSetter />
      <FranchiseDialog />
      {location.pathname !== '/ssoprocessing' && (
        <>
          <ParameterSelector />
          <HeaderComponent
            toggleLeftSideMobileMenu={toggleLeftSideMobileMenu}
          />
          <ConsentModalComponent />
          <DunningBlockModalComponent />
          <CustomerErrorModalComponent />
        </>
      )}
      <Switch>
        <Route path="/" exact>
          <PageTitle
            title={t(`headerHomeLabel`, { ns: namespace.coreSrsPrimary })}
          >
            <WelcomeComponent
              isLeftSideMobileMenuExpand={isLeftSideMenuExpand}
              toggleLeftSideMobileMenu={toggleLeftSideMobileMenu}
            />
          </PageTitle>
        </Route>
        <Route
            exact
            path={[
              '/Esst',
              '/:getLangRoute/Pages/Esst.aspx',
              '/en-US/Pages/Esst.aspx',
              '/Esst.aspx',
            ]}
          >
          <PageTitle
            title={t(`headerHomeLabel`, { ns: namespace.coreSrsPrimary })}
          >
            <EsstComponent
              isLeftSideMobileMenuExpand={isLeftSideMenuExpand}
              toggleLeftSideMobileMenu={toggleLeftSideMobileMenu}
            />
          </PageTitle>
        </Route>
        <Route path="/ssoprocessing" exact>
          <PageTitle
            title={t(`sso_browser_tab_title`, { ns: namespace.coreSrsPrimary })}
          >
            <SsoComponent />
          </PageTitle>
        </Route>
        <Route exact path="/NewsletterProcessor/:id">
          <PageTitle
            title={t(`txtProductList`, { ns: namespace.coreSrsPrimary })}
          >
            <NewsletterProcessor />
          </PageTitle>
        </Route>
        <Route exact path="/privacyLogout">
          <PrivacyLogout />
        </Route>
        <Route
            exact
            path={[
              '/ProductListCatalog',
              '/:getLangRoute/Pages/ItemListing.aspx',
              '/en-US/Pages/ItemListing.aspx',
              '/ItemListing.aspx',
            ]}
          >
          <PageTitle
            title={t(`txtProductList`, { ns: namespace.coreSrsPrimary })}
          >
            <ProductListComponent
              isLeftSideMobileMenuExpand={isLeftSideMenuExpand}
              toggleLeftSideMobileMenu={toggleLeftSideMobileMenu}
              itemListPlaceholder={itemListPlaceholder}
              isLeftNavEnabled={isLeftMenuEnabledForProductList()}
            />
          </PageTitle>
        </Route>
        <Route exact path="/getcorporateinformation">
          <PageTitle
            title={t(`txtCorporateInformation`, {
              ns: namespace.coreSrsPrimary,
            })}
          >
            <CorporateInfoComponent />
          </PageTitle>
        </Route>
        <Route exact path="/cookies">
          <PageTitle
            title={t(`txtCookiePolicy`, { ns: namespace.coreSrsPrimary })}
          >
            <AccordionComponent
              pages={cookeisPages}
              title={t(`Use_Of_Cookies_Label`, {
                ns: namespace.coreSrsPrimary,
              })}
            />
          </PageTitle>
        </Route>
        <Route exact path="/warranty">
          <PageTitle
            title={t(`Footer_Warranty`, { ns: namespace.coreSrsPrimary })}
          >
            <AccordionComponent
              pages={[
                {
                  name: t(`Footer_Warranty`, {
                    ns: namespace.coreSrsPrimary,
                  }),
                  url: welcome?.warranty,
                },
              ]}
            />
          </PageTitle>
        </Route>
        <Route exact path="/opensources">
          <PageTitle
            title={t(`txtOpenSources`, { ns: namespace.coreSrsPrimary })}
          >
            <OpenSourcesComponent />
          </PageTitle>
        </Route>
        {includeWebAccessibilityLink && (
        <Route exact path="/webaccessibility">
          <PageTitle
            title={t(`txtWebAccessibility`, { ns: namespace.coreSrsPrimary })}
          >
            <WebAccessibilityComponent />
          </PageTitle>
        </Route>
        )}
        {menuItems
          ?.filter((item) => {
            return item.linkType === LinkType.ReactMarkExt
          })
          .map((item, i) => (
            <Route
              key={item.name.concat(i.toString())}
              path={'/' + item.name.replace(/\s/g, '').toLowerCase()}
            >
              <StaticPageRender
                {...{
                  name: item.name,
                  url: item.url,
                }}
              />
            </Route>
          ))}
        <Route
            exact
            path={[
              '/ItemDetail',
              '/:getLangRoute/Pages/ItemDetail.aspx',
              '/en-US/Pages/ItemDetail.aspx',
              '/detail.aspx',
            ]}
          >
          <PageTitle
            title={t(`txtProductDetails`, { ns: namespace.coreSrsPrimary })}
          >
            <ItemDetailComponent itemListPlaceholder={itemListPlaceholder} />
          </PageTitle>
        </Route>
        {stringToBoolean(welcome?.siteSettings['Srs.EnableOthersWishList']) &&
          user?.userRoles?.find((role) => role.roleId === RoleEnum.Buyer) && (
            <Route exact path="/otherswishlist">
              <PageTitle
                title={t(`OthersWishListTitlestr`, {
                  ns: namespace.coreSrsPrimary,
                })}
              >
                <OthersWishListPage />
              </PageTitle>
            </Route>
          )}
        <Route exact path="/wishlist">
          <PageTitle
            title={t(`OtherWishListTitlestr`, { ns: namespace.coreSrsPrimary })}
          >
            <SrsWishlistComponent />
          </PageTitle>
        </Route>
        {isCartEnabled && (
          <Route exact path="/cart">
            <PageTitle title={t(`ShoppingCartstr`, { ns: namespace.coreSrsPrimary })}>
              <SrsCartComponent />
            </PageTitle>
          </Route>
        )}
        {isLoanEnabled && (
          <Route exact path="/loanCart">
            <PageTitle
              title={t(`txtRentalCart`, { ns: namespace.coreSrsPrimary })}
            >
              <LoanCartcomponent />
            </PageTitle>
          </Route>
        )}
        <Route exact path="/repairprocedures">
          <RepairProceduresComponent />
        </Route>
        <Route exact path="/contactus">
          <PageTitle
            title={t(`Footer_ContactUsLinkText`, {
              ns: namespace.coreSrsPrimary,
            })}
          >
            <ContactUsComponent />
          </PageTitle>
        </Route>
        {!stringToBoolean(process.env.REACT_APP_HIDE_QUICKORDER) && isCartAllowed && (
          <Route exact path="/quickOrder">
            <PageTitle
              title={t(`lblQuickOrderHeader`, {
                ns: namespace.coreSrsPrimary,
              })}
            >
              <QuickOrderComponent />
            </PageTitle>
          </Route>
        )}
        {isCartAllowed && (
          <Route exact path="/checkout">
            <PageTitle title={t(`btnCheckOutToolTip`, { ns: namespace.coreSrsPrimary })}>
              <CheckoutComponent />
            </PageTitle>
          </Route>
        )}
        {isLoanEnabled && (
          <Route exact path="/loancheckout">
            <PageTitle
              title={t(`btnCheckOutToolTip`, { ns: namespace.coreSrsPrimary })}
            >
              <LoanCheckoutComponent />
            </PageTitle>
          </Route>
        )}
        {renderLoginRoutes().map(({ component, pageTitle, path }) => (
          <Route exact path={path} key={path}>
            <PageTitle title={pageTitle}>{component}</PageTitle>
          </Route>
        ))}
        <Route exact path="/orderHistoryDetails">
          <PageTitle
            title={t(`txtOrderHistory`, { ns: namespace.coreSrsPrimary })}
          >
            <OrderHistoryDetailsComponent />
          </PageTitle>
        </Route>
        <Route exact path="/thankyou">
          <PageTitle
            title={t(`CheckoutV2_ThankYou`, { ns: namespace.coreSrsPrimary })}
          >
            <ThankyouComponent />
          </PageTitle>
        </Route>
        <Route exact path="/mypreferences">
          <PageTitle
            title={t(`txtPreference`, { ns: namespace.coreSrsPrimary })}
          >
            <PrefferencesComponent />
          </PageTitle>
        </Route>
        <Route exact path="/changePassword">
          <PageTitle
            title={t(`btnChangePasswordText`, { ns: namespace.coreSrsPrimary })}
          >
            <PrefferencesChangePasswordComponent />
          </PageTitle>
        </Route>
        <Route exact path="/orderHistory">
          <PageTitle
            title={t(`txtOrderHistory`, { ns: namespace.coreSrsPrimary })}
          >
            <OrderHistoryComponent />
          </PageTitle>
        </Route>
        <Route exact path="/webPartEditor">
          <WebPartEditorComponent />
        </Route>
        {
          getLangRoute ? (
            <Route exact path="/:getLangRoute/userActivation">
              <EnhancedRegistrationActivation />
            </Route>
          ) : (
            <Route exact path="/en-US/userActivation">
              <EnhancedRegistrationActivation />
            </Route>
          )
        }
        <Route exact path="/userActivation">
          <EnhancedRegistrationActivation />
        </Route>
        <Route exact path="/virtualtoolbox">
          <VirtualToolBoxComponent />
        </Route>
        {isLoanEnabled && (
          <Route exact path="/LoanTools">
            <LoanToolProduct
              isLeftSideMobileMenuExpand={isLeftSideMenuExpand}
              toggleLeftSideMobileMenu={toggleLeftSideMobileMenu}
            />
          </Route>
          )}
          <Route
            exact
            path={[
              '/ToolOrganization',
              '/:getLangRoute/Pages/ToolOrganization.aspx',
              '/en-US/Pages/ToolOrganization.aspx',
              '/ToolOrganization.aspx',
            ]}
          >
          <PageTitle
            title={t(`headerHomeLabel`, { ns: namespace.coreSrsPrimary })}
          >
            <MinimumStandardComponent
              isLeftSideMobileMenuExpand={isLeftSideMenuExpand}
              toggleLeftSideMobileMenu={toggleLeftSideMobileMenu}
            />
          </PageTitle>
        </Route>
        {/* THIS ROUTE SHOULD BE LAST */}
        <Route>
          <PageTitle
            title={t(`Error404Text`, { ns: namespace.coreSrsPrimary })}
          >
            <NotFoundPageComponent />
          </PageTitle>
        </Route>
      </Switch>
      {location.pathname !== '/ssoprocessing' && <FooterComponent />}
    </div>
  )
}

export default SharedApp
