import LeftMenuComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.leftMenu.component'
import { useAppDispatch, useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks'
import { Container, Row, Col } from 'react-bootstrap'
import { useEffect } from 'react'
import {
    discard,
    marketingContent,
    promote,
    resetStatus,
    status as reduxStatus,
} from 'srs.sharedcomponents/lib/esm/redux/slices/webPartEditorSlice'
import { IZone } from 'srs.sharedcomponents/lib/esm/models/webPartEditor/srs.zone.model'
import CmsButtonsComponent from 'srs.sharedcomponents/lib/esm/components/webPartEditor/srs.cmsButtons.component'
import { getWelcome } from 'srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice'
import { CMSEditorTypes, stateStatusWebPartEditor, WelcomeZonesNames } from 'srs.sharedcomponents/lib/esm/utils/enums'
import {
    fillRotatingImages,
    fillSingleImage,
    getImgValues,
    getRotatingImagesValues,
} from 'srs.sharedcomponents/lib/esm/utils/helpers'
import { HTMLElement, parse } from 'node-html-parser'
import { rotatingImagesTemplate, singleImageTemplate } from 'srs.sharedcomponents/lib/esm/utils/constants'
import { IImagePoco } from 'srs.sharedcomponents/lib/esm/models/webPartEditor/IImagePoco'
import htmlParser from 'html-react-parser'

interface IEsstComponentProps {
    isLeftSideMobileMenuExpand?: boolean
    toggleLeftSideMobileMenu?: () => void
}

const EsstComponent = (props: IEsstComponentProps) => {
    const leftNavEnabled = true
    const welcome = useAppSelector((state) => state?.welcome?.welcome)
    const cmsEditPermission = useAppSelector(
        (state) =>
            state?.user?.user?.permissions?.includes('CMS') && state?.webPartEditor?.editContentToggle,
    )
    const webPartContent = useAppSelector(marketingContent)
    const isContentEditorEnabled = cmsEditPermission && webPartContent
    const dispatch = useAppDispatch()
    const reduxStateStatus = useAppSelector(reduxStatus)
    const getWebPartZoneByName = (zoneName: string): IZone | undefined => {
        return webPartContent?.zones?.find((zone) => zone.name === zoneName)
    }
    const getWelcomeZoneContentByName = (zoneName: string): string => {
        let currentZone = welcome?.zones?.find((zone) => zone.name === zoneName)
        if (currentZone) {
            let tempHtml = parse(currentZone.content)
            let contentType = ''
            try {
                contentType =
                    (tempHtml.firstChild as HTMLElement).attributes['data-ContentType'] ||
                    (tempHtml.firstChild as HTMLElement).attributes['data-contenttype']
                const imagesCount = tempHtml.getElementsByTagName('img').length
                if (imagesCount === 1) {
                    contentType = CMSEditorTypes.SingleImageLink
                }
                if (imagesCount > 1) {
                    contentType = CMSEditorTypes.RotatingImages
                }
            } catch (e) { }
            let template = ''
            switch (contentType) {
                case CMSEditorTypes.SingleImageLink: {
                    let tempImgPoco = getImgValues(tempHtml)
                    template = singleImageTemplate
                    copyImagePropertiesAsNew(tempImgPoco)
                    return fillSingleImage(template, tempImgPoco)
                }

                case CMSEditorTypes.RotatingImages: {
                    let rotImages = getRotatingImagesValues(tempHtml)
                    template = rotatingImagesTemplate
                    // Removes leftover arrows from old implementation of the cms
                    rotImages = rotImages.filter((image) => !isAbsolutePath(image.imageUrl))
                    rotImages.forEach((image, index) => {
                        rotImages[index] = copyImagePropertiesAsNew(image)
                    })
                    return fillRotatingImages(template, rotImages)
                }

                default: {
                    return currentZone.content
                }
            }
        }
        return ''
    }

    const isAbsolutePath = (path: string) => {
        return path?.startsWith('/');
    }
    const copyImagePropertiesAsNew = (image: IImagePoco) => {
        image.newCurrentLinkType = image.currentLinkType
        image.newCurrentLink = image.currentLink
        image.newLinkOpenNewTab = image.linkOpenNewTab
        return image
    }
    const onPromote = (zoneName: string) => {
        dispatch(
            promote({
                TempContent: getWebPartZoneByName(zoneName)?.tempContent as string,
                ContentType: 1,
                TempFolderPath: getWebPartZoneByName(zoneName)?.tempFolderPath as string,
            }),
        )
    }
    useEffect(() => {
        if (reduxStateStatus === stateStatusWebPartEditor.promoteFulfilled) {
            dispatch(getWelcome())
            dispatch(resetStatus())
        }
    }, [reduxStateStatus])
    const onDiscard = (zoneName: string) => {
        dispatch(discard({ TempFolderPath: getWebPartZoneByName(zoneName)?.tempFolderPath as string }))
    }
    if (!welcome || welcome.anonymousId === '') return <></>
    return (
        <Container>
            <main id="main" role="main" className="pb-3">
                <Row>
                    {leftNavEnabled && (
                        <Col
                            className={`${props.isLeftSideMobileMenuExpand ? '' : 'd-none '
                                } d-xl-block d-lg-block`}
                        >
                            <LeftMenuComponent
                                items={welcome.leftCatalog}
                                toggleLeftSideMobileMenu={props.toggleLeftSideMobileMenu}
                            />
                        </Col>
                    )}
                    <Col md={12} lg={leftNavEnabled ? 9 : 12} xl={leftNavEnabled ? 9 : 12}>
                        <Row className="welcome">
                            <div
                                className="col-md-8 col-sm-8 first-image position-relative"
                                id="welcomeZoneImage"
                            >
                                {getWebPartZoneByName(WelcomeZonesNames.MainZone)?.tempContent
                                    ? htmlParser(
                                        getWebPartZoneByName(WelcomeZonesNames.MainZone)?.tempContent as string,
                                    )
                                    : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.MainZone))}
                                {isContentEditorEnabled && (
                                    <CmsButtonsComponent
                                        zone={getWebPartZoneByName(WelcomeZonesNames.MainZone)}
                                        onPromote={onPromote}
                                        onDiscard={onDiscard}
                                    />
                                )}
                            </div>
                        </Row>
                    </Col>
                </Row>
            </main>
        </Container>
    )
}
export default EsstComponent
